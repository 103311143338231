import React, { useState, useRef, useEffect } from 'react';

const ReadMore = ({ content }) => {
  const [expanded, setExpanded] = useState(false);
  const [hasOverflow, setHasOverflow] = useState(false);
  const contentRef = useRef();

  useEffect(() => {
    if (contentRef.current.scrollHeight > contentRef.current.offsetHeight) {
      setHasOverflow(true);
    }
  }, [content]);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="speaker-content-wrapper">
      <p
        ref={contentRef}
        className="speaker-content"
        style={{
          display: '-webkit-box',
          WebkitLineClamp: expanded ? '' : '3',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
        }}
      >
        {content}
      </p>
      {hasOverflow && (
        <button className="read-more" onClick={toggleExpanded}>
          {expanded ? 'Read Less' : 'Read More'}
        </button>
      )}
    </div>
  );
};

export default ReadMore;
