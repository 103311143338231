import React, { useState } from 'react';
import './Cookies.css';

const Cookies = () => {
  const [accepted, setAccepted] = useState(localStorage.getItem('cookies'));

  const handleAccept = () => {
    localStorage.setItem('cookies', 'accepted');
    setAccepted(true);
  };

  return (
    <div className="cookies-container">
      {!accepted && (
        <div className="cookies-overlay">
          <div className="cookies-text">
            <p>We use cookies to improve your experience on our website. By continuing to browse, you consent to our use of cookies.</p>
            <div className="cookies-links">
              <a href="https://warsawjs.com/privacy-policy">Privacy Policy</a>
              <a href="https://warsawjs.com/terms-and-conditions">Terms and Conditions</a>
            </div>
            <button onClick={handleAccept}>Accept</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cookies;