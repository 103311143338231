import React from 'react';
import ReadMore from './ReadMore';

const Speakers = ({ speakers }) => {
  return (
    <section id="speakers">
      <h2>Speakers</h2>
      <ul className="speakers">
      {speakers.map((speaker) => (
        <li className="speaker" key={speaker.id}>
          <div className="avatar-placeholder">
            <img src={speaker.image} alt={`${speaker.name} Avatar`} />
          </div>
          <h3>{speaker.name}</h3>
          <h4>Talk: {speaker.talkTitle}</h4>
          <ReadMore content={speaker.talkDescription} maxHeight={96} />
          <ReadMore content={speaker.bio} maxHeight={96} />
          <div className="social-links">
            <a href={speaker.twitter} target="_blank" rel="noopener noreferrer">
              Twitter
            </a>
            <a href={speaker.linkedIn} target="_blank" rel="noopener noreferrer">
              LinkedIn
            </a>
          </div>
        </li>
      ))}
      </ul>
    </section>
  );
};

export default Speakers;
