import React from 'react';
import './App.css';
import tomek from './assets/speakers/tomasz-kolinko.jpg';
import piotr from './assets/speakers/piotr-zientara.jpg';
import sasha from './assets/speakers/sasha-michalowska.jpg';
import iga from './assets/speakers/iga-trydulska.jpg';
import kacper from './assets/speakers/kacper-wikiel.jpg';
import logo from './assets/ConfrontAI.jpg';
import Speakers from './Speakers';
import ReactGA from 'react-ga';
import Cookies from './Cookies';
  
  const TRACKING_ID = "AW-11147126785"; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);
  ReactGA.pageview(window.location.pathname + window.location.search);

const speakers = [
  {
    id: 1,
    name: 'Tomasz Kolinko',
    image: tomek,
    talkTitle: 'TBA',
    talkDescription: '',
    bio: "I spend most of my time in Warsaw, building cool things, mentoring startups and so on. A few times a year I visit Berlin and San Francisco - feel free to chat me up if you're from one of these places. I've also built a few other projects in the past: AppCodes - App Store SEO tool Orisi - a possibly first whitepaper about decentralised oracles Eveem.org - a smart contract decompiler. Freespace Warsaw - a short lived climate tech venture builder, now an awesome mancave.",
    twitter: 'https://twitter.com/kolinko',
    linkedIn: 'https://www.linkedin.com/in/kolinko/',
  },
  {
    id: 2,
    name: 'Iga Trydulska',
    image: iga,
    talkTitle: 'Fashion & AI',
    talkDescription: '',
    bio: "Fashion journalist, AI studies graduate",
    twitter: 'https://twitter.com/',
    linkedIn: 'https://www.linkedin.com/in/iga-trydulska/',
  },
  {
    id: 3,
    name: 'Kacper Wikieł',
    image: kacper,
    talkTitle: 'Practical application of a private LLM Box in a company',
    talkDescription: 'Software Engineer @ DS STREAM | FastAPI, API Development, Interested in Python LLM Langchain',
    bio: "",
    twitter: 'https://twitter.com/confrontai_',
    linkedIn: 'https://www.linkedin.com/in/kacper-wikiel/',
  },
];

const App = () => {
  return (
    <div className="App">
      <Cookies />
      <header className="App-header">
        <img className="logo" src={logo} alt="Confront.AI Conference Logo" />
        <p>The Confront.AI Conference is a series of events dedicated to practical applications of artificial intelligence.</p>
        <br/>
        <p>The conference is also a unique opportunity to expand your network and meet like-minded individuals with different skill sets.</p>

      </header>

      <section id="meetup-date">
        <h2>Meetup Date</h2>
        <p>26.07.2024</p>
        <p>18:00</p>
        <p>Warsztat Powązkowska 24b</p>
        <ReactGA.OutboundLink 
          eventLabel="conversion"
          target="_blank"
          to="https://app.easycart.pl/checkout/warsawjs/confrontai"
          rel="noopener noreferrer"
        >
          <button>Get Tickets</button>
        </ReactGA.OutboundLink>
        <h3>
          Byte into the future at Confront.AI Conference - the tastiest AI event in town!
        </h3>
      </section>

      <Speakers speakers={speakers} />

      <section id="founders">
        <h2>Organizers</h2>
        <div className="organizer">
          <div className="avatar-placeholder">
            <img src={piotr} alt="Piotr Avatar" />
          </div>
          <h3>Piotr Zientara</h3>
          <p>Connector</p>
          <div className="social-links">
            <a href="https://twitter.com/Piotr_Zientara" target="_blank" rel="noopener noreferrer">Twitter</a>
            <a href="https://www.linkedin.com/in/piotr-zientara/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
          </div>
        </div>
        <div className="organizer">
          <div className="avatar-placeholder">
            <img src={sasha} alt="Sasha Avatar" />
          </div>
          <h3>Sasha Michałowska</h3>
          <p>Organizer</p>
          <div className="social-links">
            <a href="https://x.com/sasha_faang" target="_blank" rel="noopener noreferrer">Twitter</a>
            <a href="https://www.linkedin.com/in/sasha-rust/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
          </div>
        </div>
      </section>


      <section id="location">
        <h2>Location</h2>
        <p>Warsztat Powązkowska 24b</p>
        <iframe
          title="Powązkowska 24b"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2442.3939716478194!2d20.974389700000003!3d52.2543905!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ecb9ab45f5691%3A0x3595db3e5892baf4!2sPow%C4%85zkowska%2024%2C%2001-797%20Warszawa!5e0!3m2!1sen!2spl!4v1720096885364!5m2!1sen!2spl"
          width="600"
          height="450"
          loading="lazy"
        ></iframe>
      </section>

      <footer>
        <a href="https://www.meetup.com/ConfrontAI/" target="_blank" rel="noopener noreferrer">Join us on Meetup.com</a>
      </footer>
    </div>
  );
};

export default App;
